const raceData = {
  covid19: [
    [
      "Covid19 OverTime",
      "2020-3-12",
      "2020-3-19",
      "2020-3-26",
      "2020-4-02",
      "2020-4-09",
      "2020-4-16",
      "2020-4-23",
      "2020-4-30",
      "2020-5-07",
      "2020-5-14",
      "2020-5-21",
      "2020-5-28",
      "2020-6-04",
      "2020-6-11",
      "2020-6-18",
      "2020-6-25",
      "2020-6-28",
    ],
    [
      "美国",
      "1561",
      "14153",
      "84080",
      "244593",
      "464442",
      "669272",
      "871617",
      "1072667",
      "1261409",
      "1423726",
      "1584486",
      "1730259",
      "1878543",
      "2023590",
      "2191052",
      "2422299",
      "2510281",
    ],
    [
      "巴西",
      "52",
      "621",
      "2985",
      "8044",
      "18092",
      "30425",
      "50036",
      "87187",
      "135773",
      "203165",
      "310087",
      "438238",
      "614941",
      "802828",
      "978142",
      "1228114",
      "1313667",
    ],
    [
      "俄罗斯",
      "28",
      "199",
      "840",
      "3548",
      "10131",
      "27938",
      "62773",
      "106498",
      "177160",
      "252245",
      "317554",
      "379051",
      "440538",
      "501800",
      "560321",
      "613148",
      "626779",
    ],
    [
      "印度",
      "73",
      "194",
      "727",
      "2543",
      "6725",
      "13430",
      "23077",
      "34863",
      "56351",
      "81997",
      "118226",
      "165386",
      "226713",
      "297535",
      "380532",
      "490401",
      "528859",
    ],
    [
      "英国",
      "456",
      "2689",
      "11658",
      "33718",
      "65077",
      "103093",
      "138078",
      "171253",
      "206715",
      "233151",
      "250908",
      "269127",
      "281661",
      "291409",
      "300469",
      "307980",
      "311727",
    ],
    [
      "秘鲁",
      "15",
      "234",
      "580",
      "1414",
      "5256",
      "12491",
      "20914",
      "36976",
      "58526",
      "80604",
      "108769",
      "141779",
      "183198",
      "214788",
      "244388",
      "268602",
      "275989",
    ],
    [
      "智利",
      "23",
      "238",
      "1306",
      "3404",
      "5972",
      "8807",
      "11812",
      "16023",
      "24581",
      "37040",
      "57581",
      "86943",
      "118292",
      "154092",
      "225103",
      "259064",
      "267766",
    ],
    [
      "西班牙",
      "2277",
      "17963",
      "57786",
      "112065",
      "153222",
      "184948",
      "213024",
      "213435",
      "221447",
      "229540",
      "233037",
      "237906",
      "240660",
      "242707",
      "245268",
      "247486",
      "248469",
    ],
    [
      "意大利",
      "15113",
      "41035",
      "80589",
      "115242",
      "143626",
      "168941",
      "189973",
      "205463",
      "215858",
      "223096",
      "228006",
      "231732",
      "234013",
      "236142",
      "238159",
      "239706",
      "240136",
    ],
    [
      "伊朗",
      "10075",
      "18407",
      "29406",
      "50468",
      "66220",
      "77995",
      "87026",
      "94640",
      "103135",
      "114533",
      "129341",
      "143849",
      "164270",
      "180156",
      "197647",
      "215096",
      "220180",
    ],
    [
      "墨西哥",
      "12",
      "164",
      "585",
      "1510",
      "3441",
      "6297",
      "11633",
      "19224",
      "29616",
      "42595",
      "59567",
      "81400",
      "105680",
      "133974",
      "165455",
      "202951",
      "212802",
    ],
    [
      "巴基斯坦",
      "28",
      "501",
      "1373",
      "2686",
      "4695",
      "7025",
      "11940",
      "18114",
      "26435",
      "38799",
      "50694",
      "64028",
      "89249",
      "125933",
      "165062",
      "195745",
      "202955",
    ],
    [
      "土耳其",
      "1",
      "192",
      "3629",
      "18135",
      "42282",
      "74193",
      "101790",
      "120204",
      "133721",
      "144749",
      "153548",
      "160979",
      "167410",
      "174023",
      "184031",
      "193115",
      "195883",
    ],
    [
      "德国",
      "2078",
      "15320",
      "43938",
      "84794",
      "118181",
      "137698",
      "153129",
      "163009",
      "169430",
      "174478",
      "179021",
      "182196",
      "184472",
      "186691",
      "189817",
      "193371",
      "194458",
    ],
    [
      "法国",
      "2281",
      "10871",
      "29155",
      "59105",
      "86334",
      "144944",
      "157026",
      "165764",
      "173040",
      "176712",
      "179306",
      "183309",
      "185986",
      "188354",
      "190107",
      "191288",
      "199473",
    ],
    [
      "沙特",
      "45",
      "274",
      "1012",
      "1885",
      "3287",
      "6380",
      "13930",
      "22753",
      "33731",
      "46869",
      "65077",
      "80185",
      "93157",
      "116021",
      "145991",
      "170639",
      "178504",
    ],
    [
      "孟加拉国",
      "3",
      "17",
      "44",
      "56",
      "330",
      "1572",
      "4186",
      "7667",
      "12425",
      "18863",
      "28511",
      "40321",
      "57563",
      "78052",
      "102292",
      "126606",
      "133978",
    ],
    [
      "南非",
      "17",
      "150",
      "927",
      "1462",
      "1934",
      "2605",
      "3953",
      "5647",
      "8232",
      "12739",
      "19137",
      "27403",
      "40792",
      "58568",
      "83890",
      "118375",
      "131800",
    ],
    [
      "加拿大",
      "117",
      "800",
      "4042",
      "11284",
      "20654",
      "30809",
      "43299",
      "54457",
      "66201",
      "74781",
      "82742",
      "89976",
      "95269",
      "99159",
      "101877",
      "104463",
      "104878",
    ],
    [
      "卡塔尔",
      "262",
      "460",
      "549",
      "949",
      "2376",
      "4103",
      "7764",
      "13409",
      "18890",
      "28272",
      "38651",
      "50914",
      "63741",
      "75071",
      "84441",
      "91838",
      "93663",
    ],
    [
      "中国",
      "80813",
      "80967",
      "81340",
      "81620",
      "81907",
      "82692",
      "82804",
      "82874",
      "82886",
      "82933",
      "82971",
      "82995",
      "84614",
      "84659",
      "84940",
      "85148",
      "84743",
    ],
    [
      "日本",
      "639",
      "924",
      "1387",
      "2495",
      "4667",
      "8626",
      "12368",
      "14088",
      "15477",
      "16120",
      "16424",
      "16598",
      "16911",
      "17187",
      "17588",
      "18055",
      "18268",
    ],
    [
      "韩国",
      "7869",
      "8565",
      "9241",
      "9976",
      "10423",
      "10613",
      "10708",
      "10774",
      "10822",
      "11018",
      "11142",
      "11402",
      "11668",
      "12003",
      "12306",
      "12602",
      "12715",
    ],
  ],
};

export { raceData };
