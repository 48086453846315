const raceData2 = {
  covid19: [
    ["Covid19 OverTime", "2021-01", "2021-06"],
    ["东莞虎门", "2.85", "3.06"],
    ["中山小榄", "2.12", "3.03"],
    ["惠州下埔路", "2.67", "2.73"],
    ["中山市中山四路", "2.32", "2.32"],
    ["东莞东城", "1.87", "2.03"],
    ["中山兴中道", "1.87", "1.91"],
    ["东莞中堂", "1.38", "1.88"],
    ["东莞长安", "1.67", "1.88"],
    ["惠州博罗", "1.69", "1.76"],
    ["东莞大朗", "1.17", "0.80"],
    ["惠州文明一路", "0.66", "0.76"],
    ["东莞塘厦", "0.46", "0.50"],
    ["河源越王大道", "0.29", "0.48"],
    ["东莞厚街", "0.28", "0.29"],
    ["财富管理中心", "0.40", "0.21"],
    ["中山三乡", "0.17", "0.19"],
    ["东莞常平", "0.15", "0.18"],
  ],
};

export { raceData2 };
